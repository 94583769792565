T<template>
	<div>
		<contentHeader title="Racikan Obat" url="/racikan-obat" subTitle="Tambah Data Obat" />

		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row d-flex align-items-center">
		            			<h3 class="card-title col-6">Tambah Racikan Obat</h3>
		            			<div class="col-6 text-right">

		            			</div>
		            		</div>
		            		<div class="card-body row">
		            			<div class="col-lg-2"></div>
		            			<div class="col-lg-8">
		            				<form @submit.prevent="prosesResepObat">
				            			<div class="px-lg-2 ">
						                  <div class="form-group">
						                    <label for="kode">Kode <span class="text-danger">*</span></label>
						                    <input autocomplete="off" type="text" class="form-control" id="kode" name="kode" required="" disabled="" v-model="resep_obat.kode">
						                  </div>
						                  <div class="form-group">
						                    <label for="nama">Nama <span class="text-danger">*</span></label>
						                    <input autocomplete="off" type="text" class="form-control" id="nama" name="nama" required="" v-model="resep_obat.nama">
						                  </div>
						                  <div class="form-group">
						                    <label for="harga">Harga <span class="text-danger">*</span></label>
						                    <input autocomplete="off" type="number" class="form-control" id="harga" name="harga" required="" v-model="resep_obat.harga">
						                  </div>
						                  <div class="form-group">
						                    <label for="stok">Stok <span class="text-danger">*</span></label>
						                    <input autocomplete="off" type="number" class="form-control" id="stok" name="stok" required="" v-model="resep_obat.stok">
						                  </div>
						                  <div class="form-group">
						                    <label for="signa">Frekuensi Penggunaan <span class="text-danger">*</span></label>
						                    <select class="custom-select form-control" required="" v-model="resep_obat.frekuensi_penggunaan">
						                    	<option selected="" hidden=""></option>
						                    	<option value="2 kali sehari">2 kali sehari</option>
						                    	<option value="3 kali sehari">3 kali sehari</option>
						                    	<option value="4 kali sehari">4 kali sehari</option>
						                    	<option value="Setiap 3 jam">Setiap 3 jam</option>
						                    	<option value="Setiap 4 jam">Setiap 4 jam</option>
						                    	<option value="Tidak terbatas, sesuai kebutuhan">Tidak terbatas, sesuai kebutuhan</option>
						                    	<option value="Jika dibutuhkan saja">Jika dibutuhkan saja</option>
						                    </select>
						                  </div>
						                  <div class="form-group">
						                    <label for="signa">Waktu Penggunaan <span class="text-danger">*</span></label>
						                    <select class="custom-select form-control" required="" v-model="resep_obat.waktu_penggunaan">
						                    	<option selected="" hidden=""></option>
						                    	<option value="Sebelum makan">Sebelum makan</option>
						                    	<option value="Saat Tidur">Saat Tidur</option>
						                    	<option value="Di antara waktu makan">Di antara waktu makan</option>
						                    	<option value="Setelah makan">Setelah makan</option>
						                    </select>
						                  </div>
						                  <div class="form-group">
					                    		<span>Fee Racikan <span class="text-danger">*</span></span>
					                    		<div class="input-group">
								                  <div class="input-group-prepend">
								                    <div type="button" class="btn btn-secondary">{{ label_fee }}</div>
								                  </div>
								                  <!-- /btn-group -->
					                    		  <input autocomplete="off" type="number" class="form-control" id="fee" step="any" name="fee" required="" v-model="resep_obat.fee" @keyup="changeLabelFeeResep">
								                </div>
					                      </div>
						                  <div class="row ml-1 d-flex justify-content-between pt-3 pr-2">
						                  	  <button type="submit" class="btn btn-primary d-flex align-items-center">
												<div>Submit</div>	
												<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
												  <span class="sr-only">Loading...</span>
												</div>
											  </button>
						                  	  <div class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Tambahkan Obat</div>
						                  </div>
							            </div>
			            			</form>
		            			</div>
		            			<div class="col-lg-2"></div>
		            		</div>
						</div>
					</div>
				</div>
			</div>
		</div>
 
		<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
		  <div class="modal-dialog modal-lg">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="exampleModalLabel">Tambahkan Data Obat</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        <div class="form-group">
	               <label for="harga">Obat </label>
	               <multiselect 
			          v-model="obat_select_value"
			          deselect-label="Can't remove this value"
			          track-by="produk.nama"
			          label="produk.nama"
			          placeholder=""
			          :custom-label="customSelectObat"
			          :options="obat"
			          :allow-empty="false"
			          @update:model-value="tambahObat"
			        >
			        </multiselect>
	               <p class="text-danger text-center text-sm" v-if="bahan_kosong">Stok Obat Racikan Kosong Atau Bobot Pada Obat Nol</p>
	             </div>

	               <table class="table table-bordered table-hover">
					  <thead>
					    <tr>
					      <th scope="col" class="text-sm">NO</th>	
					      <th scope="col" class="text-sm">NAMA</th>
					      <th scope="col" class="text-sm">STOK</th>
					      <th scope="col" class="text-sm">STOK (Gram)</th>
					      <th scope="col" class="text-sm">DIRACIK</th>
					      <th scope="col" class="text-sm">ACTION</th>
					    </tr>
					  </thead>
					  <tbody>
					    <tr v-for="(row, index) in resep_obat.obat" :key="row.id">
					      <td class="text-sm" scope="row">{{ index + 1 }}</td>
					      <td class="text-sm" scope="row">{{ row.nama }}</td>
					      <td class="text-sm" scope="row">{{ row.stok }} {{ row.satuan }}</td>
					      <td class="text-sm" scope="row">{{ row.total_bobot }} gram</td>
					      <td class="text-sm" scope="row">
					      	<div class="input-group mb-3">
                				<input type="number" step="any" class="form-control" :id="index" required="" :name="index" v-model="row.bobot_resep">
                				<div class="input-group-prepend">
				                  <div class="btn btn-secondary">Gram</div>
				                </div>
            				</div>
					      </td>
					      <td class="text-sm" scope="row"><button class="btn btn-block btn-outline-danger btn-xs" @click="hapusObatRacikan(row.id)">Hapus</button></td>
					    </tr>
					  </tbody>
					</table>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
		      </div>
		    </div>
		  </div>
		</div>

	</div>
</template>

<script>
	import ContentHeader from '@/components/ContentHeader2'
	import Multiselect from '@suadelabs/vue3-multiselect'

	import { computed, ref, onMounted, reactive } from 'vue'
	import store from '@/store'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import tambah_nol from '@/tambah_nol.js'
	import router from '@/router'

	export default{
		components: {
			ContentHeader,
			Multiselect
		},
		setup(){
			const user = computed(() => store.getters['auth/user'])
			const obat = ref([])
			const loading = ref(false)
			const bahan_kosong = ref(false)
			const resep_obat = reactive({
				"cabang_id" : user.value.cabang_id,
				"kode" : "",
				"nama" : "",
				"harga" : "",
				"stok" : "",
				"frekuensi_penggunaan" : "",
				"waktu_penggunaan" : "",
				"fee" : "",
				"obat" : []
			})

			const obat_select_value = ref('')
			const customSelectObat = ({ produk, stok_akhir }) => {
		      return `Nama: ${produk.nama} - Stok: ${stok_akhir} - Bobot: ${produk.bobot} gram`
		    }

			const getObat = async () => {
				let { data } = await axios.get(`api/stok_produk/getBahanBaku/${user.value.cabang_id}`)

				if (data == 'kosong') {
					bahan_kosong.value = true
					obat.value = null
				} else {
					bahan_kosong.value = false
					obat.value = data
				}
			} 

			const { getCabang } = useGenerateKode(user, resep_obat)
			const { label_fee, changeLabelFeeResep } = useFormatFeeResep(resep_obat)

			const tambahObat = () => {
				let data = obat.value.filter(row => {
					if (row.id == obat_select_value.value.id) {
						return row
					}
				})
				

				if (data[0]) {
					data = data[0]
					if (data.produk.bobot == 0) {
						bahan_kosong.value = true
						obat_select_value.value = ''
					} else {
						bahan_kosong.value = false

						if (data.total_bobot != 0) {
							resep_obat.obat.push({
								"id" : data.id,
								"nama" : data.produk.nama,
								"satuan" : data.satuan,
								"stok" : data.stok_akhir,
								"bobot_resep" : 0,
								"total_bobot" : data.total_bobot
							})
						} else {
							resep_obat.obat.push({
								"id" : data.id,
								"nama" : data.produk.nama,
								"satuan" : data.satuan,
								"stok" : data.stok_akhir,
								"bobot_resep" : 0,
								"total_bobot" : data.stok_akhir * data.produk.bobot
							})	
						}	

						obat_select_value.value = ''
					}	
				}
				
			}

			function hapusObatRacikan (index) {
				for(let j = 0; j < resep_obat.obat.length; j++) {
					if (resep_obat.obat[j].id == index) {
						resep_obat.obat.splice(j, 1)
					}
				}
			}

			const prosesResepObat = () => {
				if (resep_obat.obat == '') {
					Swal.fire({
					  text: 'Tambahkan Obat Bahan Racikan',
					  icon: 'error',
					  showConfirmButton: false,
					  timer: 2000
					})
				} else {
					loading.value = true
					axios.post(`api/resep_obat/create`, resep_obat)
					.then((response) => {
						Swal.fire({
						  title: 'Berhasil!',
						  text: 'Berhasil Menambahkan Data',
						  icon: 'success',
						  showConfirmButton: false,
						  timer: 1500
						})	
						loading.value = false
						router.push('/racikan-obat')
						console.log(response)
					})
					.catch((error) => {
						Swal.fire({
						  title: 'Gagal!',
						  text: 'Gagal Menambahkan Data',
						  icon: 'error',
						  confirmButtonText: 'Ok'
						})	
						loading.value = false
						console.log(error)
					})	
				}
				
			}


			onMounted(() => {
				getObat(),
				getCabang()
			})

			return {
				obat, resep_obat, prosesResepObat, loading, label_fee, changeLabelFeeResep, tambahObat, hapusObatRacikan, bahan_kosong, obat_select_value, customSelectObat
			}

		}
	}





	function useGenerateKode (user, resep_obat) {
		const kode_cabang = ref()
		const nomor_urut = ref('')

		const getCabang = async () => {
			await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)
	    	.then((response) => {
	    		kode_cabang.value = `${response.data.kode_cabang}`
	    		resep_obat.cabang_id = response.data.id
	    	}) 
	    	.catch((error) => {
	    		console.log(error)
	    	})

	    	resep_obat.kode = `${kode_cabang.value}-OBTRAC`

	    	getNomorUrut()
		}

		const getNomorUrut = async () => {
			await axios.get(`api/resep_obat/getForGenerateCode/${resep_obat.kode}`)
	    	.then((response) => {
	    		if(response.data == 'kosong'){
	    			nomor_urut.value = tambah_nol(1)
	    			generateKode()
	    		} else {
	    			let str = response.data
					let length = str.length
					let nlength = length - 3
					let id = str.slice(nlength, length) 
	    			id = Math.abs(id)
	    			nomor_urut.value = tambah_nol(id + 1)
	    			generateKode()
	    		}
	    	})
	    	.catch((error) => {
	    		console.log(error)
	    	})
		}

		const generateKode = () => {
			resep_obat.kode = `${kode_cabang.value}-OBTRAC${nomor_urut.value}`
		}


		return {
			generateKode, getCabang, getNomorUrut
		}
	}


	function useFormatFeeResep (resep_obat) {
		const label_fee = ref('...')

		const changeLabelFeeResep = () => {
			if (resep_obat.fee <= 100 ) { label_fee.value = 'Persen (%)' } else { label_fee.value = 'Rp.' }
		}

		return {
			label_fee,
			changeLabelFeeResep
		}
	}
</script>